<template>
  <div v-if="!message" class="flex justify-content-center full-width" style="padding: 20px; height: 400px;">
    <Loading style="height: 400px; width: 400px"/>
  </div>
  <content-card-full v-if="message && message.draft === 0">
    <template #title>
      <card-title>
        <template #title><h2 class="title">Secure Message</h2></template>
        <template #content>
          <div class="flex align-items-center">
            <Button class="clarity-btn no-pad-right text-only-btn dark-grey" @click="$router.back()"><span
                class="material-icons-round">chevron_left</span>Back to {{ getPreviousPage() }}
            </Button>
          </div>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <message-display :the-message="message"></message-display>

      <div class="flex justify-content-end">
        <Button class="clarity-btn clarity-gradient-grey ml-5" @click="downloadMessage" >
          <span class="material-icons-round mr-3" >download</span>Download Message
        </Button>
        <Button v-if="mailbox !== 'sent'" class="clarity-btn clarity-gradient-orange ml-5" @click="replyToMessage">
          Reply
        </Button>
      </div>

      <!--  // here we put previous messages. -->
      <div v-if="previousMessageID">
          <hr class="m-4" />
          <div class="flex">
              <div class="flex flex-grow-1 align-items-center">
                  <h3>Previous Emails ({{ previousMessages.length }})</h3>
              </div>
              <div class="flex align-items-center">
                  <Button v-if="showPrevious" class="clarity-btn no-pad-right text-only-btn dark-grey" @click="togglePrevious">
                      <span class="material-icons-round">expand_less</span>Hide Messages
                  </Button>
                  <Button v-else class="clarity-btn no-pad-right text-only-btn dark-grey" @click="togglePrevious">
                      <span class="material-icons-round">expand_more</span>Show Messages
                  </Button>
              </div>
          </div>
          <div v-if="showPrevious">
            <div v-for="previousMessage in previousMessages" class="m-4" :key="previousMessage">
                <div style="position: relative;">
                  <div class="messagesidebar"></div>
                  <div style="margin-left: 30px;">
                      <message-display :the-message="previousMessage"></message-display>
                  </div>
                </div>
                <hr class="m-4" />
            </div>
          </div>
      </div>

    </template>
  </content-card-full>
  <spacer-h v-if="composeReply"/>
  <OutboundMessage v-if="composeReply || isDraft" :isReply="isReply" :isDraft="isDraft"
                   :recipient="getRecipient(message)" @closeReply="closeReply"/>
</template>

<script>
import {useRouter} from "vue-router";
import {computed, ref, inject, watch, onBeforeUnmount} from "vue";
import {useStore} from "vuex";
import CardTitle from '@/components/common/CardTitle'
import ContentCardFull from '@/components/common/ContentCardFull'
import Loading from '@/components/common/Loading'
import OutboundMessage from "@/components/secure_messages/OutboundMessage";
import SpacerH from "@/components/common/layout/SpacerH";
import api from '@/core/services/api'
import MessageDisplay from "./MessageDisplay.vue";
import dayjs from "dayjs";

export default {
  name: "Message",
  components: {
    CardTitle,
    ContentCardFull,
    Loading,
    OutboundMessage,
    SpacerH,
    MessageDisplay
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const bus = inject('bus')
    const composeReply = ref(false)
    const isReply = ref()
    const isDraft = ref()
    const showPrevious = ref(false)

    const togglePrevious = () => {
      showPrevious.value = !showPrevious.value;
    }

    const getPreviousPage = () => {
      let last = null
      if(router.options.history.state && router.options.history.state.back) {
          last = router.options.history.state.back.split('?')[0]
      }
      if (last === null) {
        return 'inbox'
      }
      last = last && last.length ? last.split("/") : last

      return last[last.length - 1]
    }

    const replyToMessage = () => {
      composeReply.value = true
      isReply.value = true
      isDraft.value = false
      setTimeout(function () {
        bus.emit('messageIsReply')
      } , 600);
    }

    const closeReply = () => {
      composeReply.value = false
      isReply.value = false
      isDraft.value = false
    }

    bus.on('messageIsReply', () => {
        composeReply.value = true
        isReply.value = true
        isDraft.value = false
    })

    bus.on('messageIsDraft', () => {
      composeReply.value = false
      isReply.value = false
      isDraft.value = true
    })

    const getRecipient = (message) => {
      if (message && isDraft.value === true) {
        return message.recipient
      }
      if (isReply.value === true) {
        return message ? message.sender : false
      }

      return null
    }

    const message = computed(() => { return store.getters.getCurrentMessage})

    const previousMessageID = computed(() => {
        if(message.value) {
            return message.value.previous_message_id;
        }else{
            return null;
        }
    })

    const previousMessages = ref([])

    watch(previousMessageID, async (value) => {
        if(value){
            // we need to load the previous messages
            let previousID = value
            while(previousID !== null){
                const response = await api.get('/securemessaging/loadMessageWithID', {params: {messageID: previousID}})

                if (response) {
                    previousMessages.value.push(response)
                    if(response.previous_message_id){
                        previousID = response.previous_message_id
                    }else{
                        previousID = null
                    }
                }else {
                    previousID = null;
                }
            }
        }
    })

    const downloadMessage = () => {

        let contentString = '';

        //build the content string using the message found.
        contentString += 'From: '+message.value.sender.name+'\n';
        contentString += 'To: '+message.value.recipient.name+'\n';
        contentString += 'Subject: '+message.value.subject+'\n';
        contentString += 'Date: '+dayjs(message.value.date_sent).format('DD-MM-YYYY, HH:mm:ss')+'\n';
        contentString += 'Message: \n'+message.value.messagebody+'\n';

        previousMessages.value.forEach((previousMessage) => {
            contentString += '\n\n\n';
            contentString += 'From: '+previousMessage.sender.name+'\n';
            contentString += 'To: '+previousMessage.recipient.name+'\n';
            contentString += 'Subject: '+previousMessage.subject+'\n';
            contentString += 'Date: '+dayjs(previousMessage.date_sent).format('DD-MM-YYYY, HH:mm:ss')+'\n';
            contentString += 'Message: \n'+previousMessage.messagebody+'\n';
        })

        // Creating a Blob containing the text
        var blob = new Blob([contentString], { type: 'text/plain' });
        // Creating a temporary URL for the Blob
        var url = URL.createObjectURL(blob);
        // Creating a link element
        var link = document.createElement('a');
        link.href = url;
        link.download = message.value.subject.replace(/[^a-zA-Z]/g, "")+'.txt';
        // Appending the link to the body
        document.body.appendChild(link);
        // Triggering a click on the link to initiate the download
        link.click();
        // Removing the link from the DOM
        document.body.removeChild(link);
        // Revoking the URL to free up resources
        URL.revokeObjectURL(url);
    }

    onBeforeUnmount(() => {
        bus.off('messageIsReply')
        bus.off('messageIsDraft')
    });

    return {
      closeReply,
      getPreviousPage,
      getRecipient,
      replyToMessage,
      composeReply,
      isReply,
      isDraft,
      mailbox: computed(() => store.getters.mailboxType),
      message,
      previousMessageID,
      previousMessages,
      showPrevious,
      togglePrevious,
      downloadMessage
    }
  }
}
</script>

<style scoped>
.message-detail-item {
  padding: 15px 30px 15px 0;
}
.message-detail-item > div {
  margin-top: 15px;
}
label {
  margin: 10px 0px;
  font-weight: bold;
}
.clarity-btn {
  padding: 15px 45px;
}
.text-only-btn {
  box-shadow: unset;
  border: none;
  background: none;
}
.text-only-btn:hover {
  background: none;
  color: var(--clarity-dark-grey);
}
.sender-link, .sender-link:visited {
  text-decoration: underline;
  color: var(--clarity-light-blue);
}
.dark-grey:hover {
  color: var(--clarity-dark-grey) !important;
}
.no-pad-right {
  padding-right: 0 !important;
}
.sm-attachment-btn {
  display: flex;
  align-items: center;
}
.messagesidebar {
    background: var(--clarity-snow-grey) 0% 0% no-repeat padding-box;
    width: 5px;
    border-radius: 5px;
    opacity: 1;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
hr {
    color: var(--clarity-light-grey);
}
</style>
